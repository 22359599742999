<template>
  <button class="flex items-center justify-center rounded-lg" @click="back_to_top">
    {{ t("back-to-top") }}
  </button>
</template>

<script>
export default {
  name: "ButtonBackTop",
  setup() {
    const { t } = useI18n({
      useScope: "local",
    });

    return { t };
  },
  methods: {
    back_to_top() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style scoped></style>
<i18n lang="json">
{
  "fr": {
    "back-to-top": "Retour en haut"
  },
  "en": {
    "back-to-top": "Back to top"
  }
}
</i18n>
